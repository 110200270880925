
window.___gcfg = {
	lang: "en-GB",
	parsetags: "onload"
};

new WOW({ offset: 70 }).init();

$(document).ready(function () {



	if ($("#HomepageMarker").length === 0) {
		$(".header__logo, .header__starburst").css("display", "block");
	}
	else {
		$(".category-links__link").addClass("wow fadeIn").attr({ "data-wow-duration": "1.5s", "data-wow-offset": "120" });
		if (window.matchMedia('(min-width:768px)').matches) {
			$(".header__logo").addClass("wow zoomIn").css("display", "block");
			$(".header__starburst").addClass("wow fadeIn2").attr({ "data-wow-delay": "1s", "data-wow-duration": "3s" }).css("display", "block");
		} else {
			$(".header__logo, .header__starburst").css("display", "block");
		}
	};


	// ADD SLIDEDOWN ANIMATION TO DROPDOWN //
	$('.toplinks').on('show.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideDown();
		window.scrollBy(0, 1);
		window.scrollBy(1, 0);
	});

	// ADD SLIDEUP ANIMATION TO DROPDOWN //
	$('.toplinks').on('hide.bs.dropdown', function (e) {
		$(this).find('.dropdown-menu').first().stop(true, true).slideUp();


	});

	$(".toplinks__dropdown-link").each(function (index, value) {
		$(this).attr("data-wow-delay", index / 10 + "s");
		$(this).attr("data-wow-offset", 0);
		$(this).addClass("wow fadeIn");
	});


	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	$(".home-gallery__previous").click(function (e) {
		var img = $(".home-gallery__slide").first();
		img.animate({ width: 0 }, 1000, "linear", function () {
			img.remove();
			$(".home-gallery__inner").append(img);
			img.width(140);
		});
		e.preventDefault();
		return false;
	});

	$(".home-gallery__next").click(function (e) {
		var img = $(".home-gallery__slide").last();
		img.width(0);
		img.remove();
		$(".home-gallery__inner").prepend(img);
		img.animate({ width: 140 }, 1000, "linear");
		e.preventDefault();
		return false;
	});

	function fixHeights() {
		$('.testimonials__container').sameHeight();
		$('.testimonials .item').sameHeight();
		$('.testimonials__link').sameHeight();
	}

	setTimeout(fixHeights, 300);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 300);
	});


});

